import {
  SAAS_EDITOR_API_ID_PATH,
  WORKFLOW_API_EDITOR_PATH,
  WORKFLOW_EDITOR_URL,
  WORKFLOW_QUERY_EDITOR_PATH,
  WORKFLOWS_JS_COLLECTION_EDITOR_PATH,
} from "ee/constants/routes/workflowRoutes";
import WorkflowJsEditor from "../../WorkflowJSEditor";
import { WorkflowPluginActionEditor } from "../../WorkflowPluginActionEditor";
import TriggerWorkflowSettings from "../../WorkflowSettingsPane/WorkflowSettings/TriggerWorkflowSettings";
import GeneralWorkflowSettings from "../../WorkflowSettingsPane/WorkflowSettings/GeneralWorkflowSettings";
import CreateNewDatasourceTab from "pages/Editor/IntegrationEditor/CreateNewDatasourceTab";
import DataSourceEditor from "pages/Editor/DataSourceEditor";
import DatasourceBlankState from "pages/Editor/DataSourceEditor/DatasourceBlankState";
import DatasourceForm from "pages/Editor/SaaSEditor/DatasourceForm";
import { SAAS_EDITOR_DATASOURCE_ID_PATH } from "pages/Editor/SaaSEditor/constants";
import {
  DATA_SOURCES_EDITOR_ID_PATH,
  DATA_SOURCES_EDITOR_LIST_PATH,
  INTEGRATION_EDITOR_PATH,
  WORKFLOW_GENERAL_SETTINGS_PATH,
  WORKFLOW_TRIGGER_SETTINGS_PATH,
} from "ee/constants/routes/appRoutes";

export const useRoutes = (path: string) => {
  return [
    {
      key: "PluginActionEditor",
      component: WorkflowPluginActionEditor,
      path: [
        `${WORKFLOW_EDITOR_URL}${WORKFLOW_QUERY_EDITOR_PATH}`,
        `${WORKFLOW_EDITOR_URL}${SAAS_EDITOR_API_ID_PATH}`,
        `${WORKFLOW_EDITOR_URL}${WORKFLOW_API_EDITOR_PATH}`,
      ],
      exact: true,
    },
    {
      key: "WorkflowJsEditor",
      component: WorkflowJsEditor,
      path: `${WORKFLOW_EDITOR_URL}${WORKFLOWS_JS_COLLECTION_EDITOR_PATH}`,
      exact: true,
    },
    {
      key: "WorkflowTriggerSettings",
      component: TriggerWorkflowSettings,
      path: WORKFLOW_TRIGGER_SETTINGS_PATH(path),
      exact: true,
    },
    {
      key: "WorkflowGeneralSettings",
      component: GeneralWorkflowSettings,
      path: WORKFLOW_GENERAL_SETTINGS_PATH(path),
      exact: true,
    },
    {
      key: "Datasource Create and Active",
      component: CreateNewDatasourceTab,
      exact: true,
      path: `${path}${INTEGRATION_EDITOR_PATH}`,
    },
    {
      key: "DatasourceEditor",
      component: DataSourceEditor,
      exact: true,
      path: `${path}${DATA_SOURCES_EDITOR_ID_PATH}`,
    },
    {
      key: "DatasourceBlankState",
      component: DatasourceBlankState,
      exact: true,
      path: `${path}${DATA_SOURCES_EDITOR_LIST_PATH}`,
    },
    {
      key: "SAASDatasourceEditor",
      component: DatasourceForm,
      exact: true,
      path: `${path}${SAAS_EDITOR_DATASOURCE_ID_PATH}`,
    },
  ];
};
