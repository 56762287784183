import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { noop } from "lodash";
import type { RouteComponentProps } from "react-router";

import JsEditorForm from "pages/Editor/JSEditor/Form";
import { getJSCollectionDataByBaseId } from "selectors/editorSelectors";
import { saveJSObjectNameBasedOnIdeType } from "ee/actions/helpers";
import { getIsWorkflowEditorInitialized } from "ee/selectors/workflowSelectors";
import WorkflowJsEditorContextMenu from "./WorkflowJSEditorContextMenu";
import Loader from "../ModuleEditor/Loader";
import type { SaveActionNameParams } from "PluginActionEditor";
import { IDE_TYPE } from "ee/entities/IDE/constants";

interface WorkflowJsEditorRouteParams {
  baseCollectionId: string;
}

type WorkflowJsEditorProps = RouteComponentProps<WorkflowJsEditorRouteParams>;

function WorkflowJsEditor(props: WorkflowJsEditorProps) {
  const { baseCollectionId } = props.match.params;
  const isWorkflowEditorInitialized = useSelector(
    getIsWorkflowEditorInitialized,
  );
  const jsCollectionData = useSelector((state) =>
    getJSCollectionDataByBaseId(state, baseCollectionId),
  );

  const jsCollection = jsCollectionData?.config;
  const contextMenu = useMemo(() => {
    if (!jsCollection) {
      return null;
    }

    return <WorkflowJsEditorContextMenu jsCollection={jsCollection} />;
  }, [jsCollection]);

  const onSaveName = useCallback(
    ({ name }: SaveActionNameParams) => {
      return saveJSObjectNameBasedOnIdeType(
        jsCollection?.id ?? "",
        name,
        IDE_TYPE.Workflow,
      );
    },
    [jsCollection?.id],
  );

  if (!isWorkflowEditorInitialized || !jsCollection) {
    return <Loader />;
  }

  return (
    <JsEditorForm
      contextMenu={contextMenu}
      hideContextMenuOnEditor={Boolean(
        jsCollectionData?.config.isMainJSCollection,
      )}
      hideEditIconOnEditor={Boolean(
        jsCollectionData?.config.isMainJSCollection,
      )}
      jsCollectionData={jsCollectionData}
      onUpdateSettings={noop}
      saveJSObjectName={onSaveName}
      showNameEditor
      showSettings={false}
    />
  );
}

export default WorkflowJsEditor;
