import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { noop } from "lodash";
import type { RouteComponentProps } from "react-router";

import Loader from "./Loader";
import JsEditorForm from "pages/Editor/JSEditor/Form";
import ModuleJSEditorContextMenu from "./ModuleJSEditorContextMenu";
import { getIsPackageEditorInitialized } from "ee/selectors/packageSelectors";
import { getJSCollectionDataByBaseId } from "selectors/editorSelectors";
import {
  type SaveModuleNamePayload,
  saveModuleName,
} from "ee/actions/moduleActions";
import { saveJSObjectNameBasedOnIdeType } from "ee/actions/helpers";
import { getShowModuleReference } from "ee/selectors/moduleFeatureSelectors";
import PrivateEntityDeprecationCallout from "./PrivateEntityDeprecationCallout";
import { getHasPrivateEntity } from "ee/selectors/modulesSelector";
import { IDE_TYPE } from "ee/entities/IDE/constants";

interface ModuleJSEditorRouteParams {
  packageId: string;
  moduleId: string;
  baseCollectionId: string;
}

type ModuleJSEditorProps = RouteComponentProps<ModuleJSEditorRouteParams>;

function ModuleJSEditor(props: ModuleJSEditorProps) {
  const { baseCollectionId, moduleId } = props.match.params;
  const isPackageEditorInitialized = useSelector(getIsPackageEditorInitialized);
  const jsCollectionData = useSelector((state) =>
    getJSCollectionDataByBaseId(state, baseCollectionId),
  );
  const showModuleReference = useSelector(getShowModuleReference);
  const hasPrivateEntities = useSelector(getHasPrivateEntity);
  const jsCollection = jsCollectionData?.config;
  const contextMenu = useMemo(() => {
    if (!jsCollection) {
      return null;
    }

    return (
      <ModuleJSEditorContextMenu
        jsCollection={jsCollection}
        moduleId={moduleId}
      />
    );
  }, [jsCollection, moduleId]);

  const onSaveName = useCallback(
    ({ name }: SaveModuleNamePayload) => {
      const isPublicEntity = jsCollectionData?.config.isPublic;

      return isPublicEntity
        ? saveModuleName({
            id: moduleId,
            name,
          })
        : saveJSObjectNameBasedOnIdeType(
            jsCollection?.id ?? "",
            name,
            IDE_TYPE.Package,
          );
    },
    [moduleId, jsCollectionData?.config.isPublic, jsCollection?.id],
  );

  const notification = useMemo(
    () =>
      jsCollection?.isPublic &&
      showModuleReference &&
      hasPrivateEntities && <PrivateEntityDeprecationCallout />,
    [hasPrivateEntities, jsCollection?.isPublic, showModuleReference],
  );

  if (!isPackageEditorInitialized || !jsCollection) {
    return <Loader />;
  }

  return (
    <JsEditorForm
      contextMenu={contextMenu}
      jsCollectionData={jsCollectionData}
      notification={notification}
      onUpdateSettings={noop}
      saveJSObjectName={onSaveName}
      showNameEditor
      showSettings={false}
    />
  );
}

export default ModuleJSEditor;
